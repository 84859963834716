<template>
  <div class="page-block">

    <i18n-selector />

    <zhh-zedic-header
      v-if="isHep"
      :title="$t('homePage_pageTitleHep')"
    />
    <zhh-zedic-header v-else :title="$t('homePage_pageTitle')" />

    <div v-if="showPageDescription && !isHep" class="bodytext general-text">
      {{ $t('homePage_pageDescription') }}
    </div>

    <spinning-loader v-if="showPageSpinningLoader" />

    <transition name="fade" mode="out-in">
      <HomeMenu v-if="showHomeMenu" :loggedUser="loggedUser"/>
    </transition>

  </div>
</template>

<script>
import Utils from '@/utils/utils';
import AuthApi from '@/api/auth.api';

import HomeMenu from '@/components/HomeMenu.vue';

function getURLParams() {
  const uri = window.location.search.substring(1);
  if (uri) {
    return new URLSearchParams(uri);
  }
  return undefined;
}

function hasAuthFailed() {
  const params = getURLParams();
  return (params && params.get('auth') === 'fail');
}

function isInvalidToken() {
  const params = getURLParams();
  return (params && params.get('auth') && params.get('auth').includes('auth-token'));
}

function getAuthTokenFailedReason() {
  const params = getURLParams();
  const authError = params.get('auth');
  if (authError === 'expired-auth-token') return 'expired-token';
  if (authError === 'no-auth-token') return 'no-token';
  return 'invalid-token';
}

export default {
  name: 'HomePage',
  components: {
    HomeMenu,
  },

  data() {
    return {
      loggedUser: undefined,
      showHomeMenu: false,
    };
  },

  async mounted() {
    try {
      if (hasAuthFailed()) {
        this.$router.push({ name: 'AuthErrorPage' });
        return;
      }

      const authResponse = await AuthApi.getAuthParams();
      switch (authResponse.status) {
        case 'success': {
          this.handleAuthenticatedUser();
          break;
        }
        case 'error': {
          const { authParams } = authResponse;
          this.handleNotAuthenticatedUser(authParams);
          break;
        }
        default:
          break;
      }
    } catch (e) {
      this.$router.push({ name: 'CustomErrorPage' });
    }
  },

  methods: {

    async handleAuthenticatedUser() {
      const loggedUserResult = await AuthApi.getMe();
      this.loggedUser = loggedUserResult.data;
      const { roles = [], features = [] } = this.loggedUser;
      const isManager = roles.includes('MANAGER');
      const isUser = roles.includes('USER');
      const hasCovidTestEnabled = features.includes('COVID_TEST_TRACKING');

      if (this.isHepProgram()) {
        this.$router.push({ name: 'HepProgram_HomePage' });
      } else if (hasCovidTestEnabled || this.isHep()) {
        this.showHomeMenu = true;
      } else if (isManager || isUser) {
        const managerOnly = isManager && !isUser;
        const userOnly = !isManager && isUser;
        if (managerOnly) {
          this.$router.push({ name: 'MainDashboardPage' });
        } else if (userOnly) {
          this.$router.push({ name: 'UploadVaccinationPage' });
        } else {
          this.showHomeMenu = true;
        }
      } else {
        this.$router.push({ name: 'AuthErrorPage' });
      }
    },

    async handleNotAuthenticatedUser(authParams) {
      const { method, url } = authParams;
      if (method === 'EMP_ID' || method === 'EMAIL') {
        const params = { authType: method };
        if (isInvalidToken()) {
          params.authFailed = {
            reason: getAuthTokenFailedReason(),
          };
        }
        this.$router.push({ name: 'LoginPage', params });
      } else if (method === 'SSO' && url) {
        window.location.href = `${Utils.getBaseURL()}${url}`;
      } else {
        this.$router.push({ name: 'CustomErrorPage' });
      }
    },

    isHep() {
      return Utils.isHepClient();
    },

    isHepProgram() {
      return Utils.isHepProgramClient();
    },
  },

  computed: {
    showPageDescription() {
      return this.showHomeMenu;
    },

    showPageSpinningLoader() {
      return !this.showHomeMenu;
    },
  },
};
</script>

<style scoped>

</style>
