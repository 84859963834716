<template>
  <div>
    <div v-if="isHep()">
      <div class="menu-container">
        <div @click="goToUploadVaccinationPage">
          <div class="button w-button menu-button menu-button-with-desc">
            <div>COVID-19</div>
            <div class="desc">
              {{ $t('homePage_hepClient_uploadVaccinationCardOrRecord') }}
            </div>
          </div>
        </div>
        <div @click="goToUploadHepVaccinationPage">
          <div class="button w-button menu-button menu-button-with-desc">
            <div>Hepatitis A</div>
            <div class="desc">
              {{ $t('homePage_hepClient_uploadVaccinationHepCardOrRecord') }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="covidTestTrackingEnabled()" class="menu-container">
        <div @click="goToUploadCovidTestPage">
          <div class="button w-button menu-button menu-button-with-desc">
            <div>COVID-19 Test</div>
            <div class="desc">
              {{ $t('homePage_hepClient_uploadTestCardOrRecord') }}
            </div>
          </div>
        </div>
      </div>
      <div class="page-block-item margin-top-25" style="text-align: center;">
        {{ $t('homePage_help') }}
      </div>
    </div>
    <div v-else-if="covidTestTrackingEnabled()">
      <div v-if="isManager()">
        <div class="menu-container">
          <div @click="goToMainDashboardPage">
            <span class="button w-button menu-button">
              {{ $t('homePage_dashboard') }}
            </span>
          </div>
        </div>
      </div>
      <div v-if="isUser()" >
        <div class="menu-container">
          <div @click="goToUploadVaccinationPage">
            <span class="button w-button menu-button">
              {{ $t('homePage_uploadVaccinationCardOrRecord') }}
            </span>
          </div>
          <div @click="goToUploadCovidTestPage">
            <span class="button w-button menu-button">
              {{ $t('homePage_uploadWeeklyCovidTest') }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="menu-container">
        <div v-if="isManager()" @click="goToMainDashboardPage">
          <span class="button w-button menu-button">
            {{ $t('homePage_uploadVaccinationCardOrRecordDashboard') }}
          </span>
        </div>
        <div @click="goToUploadVaccinationPage">
          <span class="button w-button menu-button">
            {{ $t('homePage_uploadVaccinationCardOrRecord') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from '@/utils/utils';

export default {
  name: 'HomeMenu',
  props: ['loggedUser'],
  methods: {

    goToMainDashboardPage() {
      this.$router.push({ name: 'MainDashboardPage' });
    },

    goToUploadVaccinationPage() {
      this.$router.push({ name: 'UploadVaccinationPage' });
    },

    goToUploadHepVaccinationPage() {
      this.$router.push({ name: 'UploadHepVaccinationPage' });
    },

    goToUploadCovidTestPage() {
      this.$router.push({ name: 'UploadCovidTestPage' });
    },

    isManager() {
      return this.loggedUser.roles?.includes('MANAGER');
    },

    isUser() {
      return this.loggedUser.roles?.includes('USER');
    },

    isHep() {
      return Utils.isHepClient();
    },

    covidTestTrackingEnabled() {
      return this.loggedUser.features?.includes('COVID_TEST_TRACKING');
    },
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .menu-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: center;
    -ms-flex-pack: justify;
    justify-content: center;
    margin-bottom: 15px;
  }

  .menu-button {
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    width: 300px;
    height: 80px;
    margin: 0 7px;
    background-color: rgba(56, 152, 236, 0);
    color: #5031D0;
  }

  .menu-button:hover {
    background-color: #5031D0;
    color: white;
  }

  .menu-button-with-desc {
    flex-direction: column;
    height: 200px;
    width: 350px;
  }

  .menu-button-with-desc .desc {
    font-size: 18px;
    font-weight: 400;
    margin-top: 10px;
    line-height: 20px;
  }

  @media screen and (max-width: 479px) {
    .label {
      margin-bottom: 0px;
    }

    .menu-container {
      width: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      margin-bottom: 0px;
    }

    .menu-button {
      width: 100%;
      height: 80px;
      margin: 0;
      margin-bottom: 15px;
      padding: 40px;
    }

    .menu-button-with-desc {
      height: 200px;
    }
  }
</style>
